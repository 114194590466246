import { TextField } from '@mui/material'
import React, { useRef } from 'react'
import RequiredTextField from '../RequiredTextField'
import { sanitisePhoneNumber } from './PhoneFieldHelper'

/**
 * Text field which restricts value to a UK phone format.
 *
 * @param {Object} props
 * @param {String} props.value
 */
const PhoneTextField = props => {
  const { value = '' } = props
  const removeRX = /\D/g
  const inputRef = useRef(null);

  let { invalid, sanitizedValue, cleanValue } = sanitisePhoneNumber(value);

  const handleChange = e => {
    let cursorPosition = e.target.selectionStart;

    const input = e.target.value;

    const { sanitizedValue } = sanitisePhoneNumber(input);

    const removeEvent = e.nativeEvent.inputType === 'deleteContentForward' || e.nativeEvent.inputType === 'deleteContentBackward';
  
    if (!removeEvent) {
      let adjustment = 1;
      if (sanitizedValue[cursorPosition - 1] !== ' ') {
          adjustment = sanitizedValue.length - input.length;
        }
      cursorPosition += adjustment; // Adjust for added/removed characters
    } 

    e.target.value = input.replace(removeRX, '')

    props.onChange(e)
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  }

  return (
    <>
      <TextField
        sx={{ display: 'none' }}
        onChange={props.onChange}
        value={cleanValue}
      />
      {(props.required && (
        <RequiredTextField
          inputRef={inputRef}
          error={invalid}
          helperText={invalid && 'Must be a valid phone number'}
          {...props}
          onChange={handleChange}
          value={sanitizedValue}
        />
      )) || (
        <TextField
          inputRef={inputRef}
          error={invalid}
          fullWidth
          helperText={invalid && 'Must be a valid phone number'}
          variant='outlined'
          {...props}
          onChange={handleChange}
          value={sanitizedValue}
        />
      )}
    </>
  )
}

export default PhoneTextField
