import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Container,
  Dialog
} from '@mui/material'
import {
  addChange,
  getInstallation,
  patchInstallation,
  patchEnvosense
} from 'app/store'
import { installationUpgrade } from 'app/store/actions/installationUpgrade'
import { AppointmentCardHeader } from 'components/molecules'
import Banner from 'components/notifications/Banner'
import { AccountNotesDialog } from 'components/organisms'
import { EnvosenseStepper, HPFullStepper } from 'components/steppers'
import { reasons, types } from 'constants/outcomes'
import { accountType, status } from 'constants/statuses'
import { tiers } from 'constants/tiers'
import React from 'react'
import { connect } from 'react-redux'
import { generatePath, withRouter } from 'react-router-dom'
import CliHelper from './CliHelper'
import {
  defaultDobStr,
  minimumDobDate,
  maximumDobDate
} from 'constants/customer'
import { getCredential } from 'providers/auth'
import { deviceMap } from 'constants/devices'
import { getPelloniaApiUrl } from 'utils/CommonFunctions'

const defaultBannerLabel = 'Everything is up to date.'
/**
 * Installation page. Provides a wizard-like experience for capturing customer
 * data, and verifying the success of an install.
 */
class InstallPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      bannerColor: 'success',
      bannerLabel: defaultBannerLabel,
      hasIntegrity: true,
      hasMounted: false,
      integrityMemento: null,
      integrityChecked: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleStepChange = this.handleStepChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEntityUpgrade = this.handleEntityUpgrade.bind(this)
  }

  /**
   * The installation object, shorthand getter derived from props.
   */
  get installation () {
    const { installation } = this.props
    return installation
  }

  /**
   * Get the appropriate stepper for the installation tier.
   */
  get stepper () {
    switch (this.installation?.tier) {
      case tiers.ENVOSENSE:
        return <EnvosenseStepper {...this.stepperProps} />
      case tiers.HP_LITE:
      case tiers.HP_PLUS:
      case tiers.HP_FULL:
      default:
        return <HPFullStepper {...this.stepperProps} />
    }
  }

  /**
   * Baseline properties to pass into each stepper.
   */
  get stepperProps () {
    const { match } = this.props
    const step = parseInt(match.params.step)

    return {
      ...this.props,
      activeStep: step,
      onChange: this.handleChange,
      onFinish: this.handleSubmit,
      onStepChange: this.handleStepChange,
      installationCompleted: this.installation?.outcome?.type === types.COMPLETED
    }
  }

  /**
   * Provide a simple flag to always prompt a second update.
   */
  componentDidMount () {
    this.setState({ hasMounted: true })
  }

  /**
   * This component can sometimes be mounted without a full state (if it's yet
   * to be rehydrated by Redux), so this is just to make sure that we always set
   * the busy state.
   *
   * Also checks for truthy `installation.submitted` and starts a patch.
   */
  componentDidUpdate () {
    const { addChange, installation, offline, patchInstallation } = this.props
    if (offline?.online && !this.state.integrityChecked) {
      this.fetchInstallation()
    }

    /**
     * Send a patch, only if the outcome has changed. Only attempt to attach envosense
     * devices if any exist for this installation.
     */
    if (installation?.submitted) {
      addChange('submitted', false, installation.id)
      patchInstallation(installation)

      const envosenseDevices = this.findEnvosenseDevices(installation)
      if (envosenseDevices.length > 0) {
        this.attachEnvosenseDevices(envosenseDevices, installation)
      }
      this.handleDone()
    }
  }

  findEnvosenseDevices (installation) {
    const devices = installation?.account?.devices
    if (!devices) {
      return []
    }
    return devices.filter(device => device.type === deviceMap.ENVOSENSE_SENSOR.type)
  }

  attachEnvosenseDevices (envosenseDevices, installation) {
    const { patchEnvosense } = this.props
    const dataForPatch = []
    envosenseDevices.forEach(device => {
      dataForPatch.push({
        account_id: installation.account.id,
        name: device.serialNumber,
        location: device.roomLocation,
        notes: device.notes
      })
    })

    if (dataForPatch.length === 0) {
      return
    }
    const fullName = `${installation.account?.customer?.title} ${installation.account?.customer?.firstName} ${installation.account?.customer?.surname}`
    patchEnvosense(
      dataForPatch,
      fullName,
      installation.account?.customer?.address1,
      installation.id
    )
  }

  /**
   * Coalesce any misc notes and append them to the exisitng account notes
   * property.
   */
  coalesceNotes () {
    const { installation } = this.props
    const daysOfWeek = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']

    const carerVisits = daysOfWeek
      .filter(day => installation?.notes?.carer?.days[day])
      .map(
        day => day.toUpperCase()
      )
      .join(',')

    const carer = installation?.notes?.carer || {}
    const carerDetails = [`Carer: ${carerVisits}`,
      carer.phone ? `Phone: ${carer.phone}` : false,
      carer.firstName || carer.surname ? `Name: ${carer.firstName} ${carer.surname}` : false,
      carer.organisationName ? `Organisation: ${carer.organisationName}` : false]

    const noteItems = [
      installation?.notes?.language
        ? `Language: ${installation.notes.language}`
        : false,
      installation?.notes?.keySafe
        ? `Keysafe: ${installation.notes.keySafe}`
        : false,
      installation?.notes?.device?.reprogrammedPhone
        ? 'Installed a reprogrammed phone.'
        : false,
      installation?.notes?.device?.landlineProvider
        ? `Landline Provider: ${installation.notes.device.landlineProvider}`
        : false,
      installation?.notes?.personalMobileProvider
        ? `Mobile Provider: ${installation.notes.personalMobileProvider}`
        : false
    ]
      
    if (installation?.notes?.hasCarer) {
      noteItems.push(...carerDetails)
    }

    const notes = noteItems.filter(i => i)
      .join('\n')
      .trim()

    return `${installation?.account?.notes}\n\n${notes}`.trim()
  }

  /**
   * If network is available, then do a preflight check to make sure the account
   * data has integrity, and hasn't been modified since uploading.
   */
  doIntegrityCheck (payload) {
    const { installation } = this.props
    if (installation.integrityHash !== payload.integrityHash) {
      this.setState({ hasIntegrity: false })
    } else {
      this.setState({ hasIntegrity: true })
    }
  }

  /**
   * Try to fetch a fresh copy of the installation. Useful to check for
   * integrity.
   */
  async fetchInstallation () {
    this.setState({
      bannerColor: 'info',
      bannerLabel: 'Checking account data...',
      integrityChecked: true
    })

    let json
    try {
      const response = await window.fetch(
        `${getPelloniaApiUrl()}installations/api/installations/${this.installation.id}`,
        {
          headers: {
            Authorization: `Bearer ${getCredential()}`
          }
        }
      )
      if (!response.ok) return
      json = await response.json()
      this.doIntegrityCheck(json)
      this.setState({
        bannerColor: 'success',
        bannerLabel: defaultBannerLabel,
        integrityMemento: json
      })
    } catch (error) {
      this.setState({
        bannerLabel: 'Account is up to date since your last update',
        integrityMemento: json
      })
    }
  }

  /**
   * Handle changes to appointment data. This will pass all changes to the Redux
   * action, which is responsible for updating the state. Properties which
   * reference the state will then rerender components if necessary.
   *
   * @param {string} name Name of the property to change
   * @param {Event} event Event object containing the value of the change
   */
  handleChange (name, event) {
    const value =
      event?.target?.type === 'checkbox'
        ? event?.target?.checked
        : event?.target?.value
    this.props.addChange(name, value, this.props.installation.id)
  }

  /**
   * Updates the path via a history change when moving between steps.
   */
  handleStepChange (step = 0) {
    const {
      history,
      match: { params },
      onStepChange
    } = this.props

    const path = generatePath(
      '/mass/:massId/collection/:collectionId/installation/:installationId/step/:step',
      {
        massId: params.massId || 0,
        collectionId: params.collectionId || 0,
        installationId: params.installationId || 0,
        step: step
      }
    )

    history.push(path)

    onStepChange?.(step)
  }

  /**
   * Handles the submission of final install data, and sets the state of the
   * install to be completed.
   *
   * We also do a final bit of data wrangling to determine the CLI.
   */
  handleSubmit () {
    const { addChange, installation } = this.props

    const contactDevice = installation?.account?.devices?.filter(
      d => d.type !== deviceMap.ENVOSENSE_SENSOR.type
    )[0]

    const cli = contactDevice?.phone || contactDevice?.msisdn

    /**
     * Infer CLI logic
     */
    const {
      account: {
        customer: { phone1, phone2, mobile }
      }
    } = installation

    /**
     * Now do some CLI wrangling to get numbers into the correct slot.
     */
    const wrangled = CliHelper.expensiveWrangle({
      cli: cli,
      phone1: phone1,
      phone2: phone2,
      mobile: mobile,
      prevCli: !installation?.keepCliContact && installation?.account.cli1
    })

    addChange('account.cli1', wrangled.cli, installation.id)
    addChange('account.customer.phone1', wrangled.phone1 || '', installation.id)
    addChange('account.customer.phone2', wrangled.phone2 || '', installation.id)
    addChange('account.customer.mobile', wrangled.mobile || '', installation.id)

    const checkDobIsValid = dateString => {
      // Convert dateString to Date object
      const dobDate = new Date(dateString)

      // Check if dobDate is a valid Date object and falls within the valid DOB range
      return (
        dobDate instanceof Date &&
        dobDate >= minimumDobDate &&
        dobDate <= maximumDobDate
      )
    }

    /**
     * Ensure that the date of birth values sent to the Pellonia API are either valid or the default DOB
     */
    if (!checkDobIsValid(installation.account.customer.dateOfBirth)) {
      addChange('account.customer.dateOfBirth', defaultDobStr, installation.id)
    }

    if (!checkDobIsValid(installation.account.customer.dateOfBirth2)) {
      addChange('account.customer.dateOfBirth2', defaultDobStr, installation.id)
    }

    /**
     * Second occupant data should be null if there is no second occupant
     */
    if (!installation.account.hasSecondOccupant) {
      addChange('account.customer.title2', null, installation.id)
      addChange('account.customer.surname2', null, installation.id)
      addChange('account.customer.firstname2', null, installation.id)
      addChange('account.customer.dateOfBirth2', null, installation.id)
      addChange('account.customer.greeting2', null, installation.id)
    }

    /**
     * Set the account to an active state.
     * Only do this step if the account is in waiting state currently
     */
    if (installation.account.status === status.WAITING) {
      addChange('account.status', status.ACTIVE, installation.id)
    }

    /**
     * Make the account type full in the case that the resident wants daily contact
     * else make the account false. This is done at this stage to simplify the
     * ContactTimeStep switch logic
     */

    if (installation?.dailyContact === false) {
      addChange('account.type', accountType.HP_LITE, installation.id)
    } else {
      addChange('account.type', accountType.HP_FULL, installation.id)
    }

    /**
     * Set a few final properties. Note that there is no explicity call to patch
     * the installation at this point — instead the `componentDidUpdate` method
     * is set up to listen for any changes to the outcome property.
     */
    const coalescedNotes = this.coalesceNotes()
    addChange('account.notes', coalescedNotes, installation.id)
    addChange('outcome.type', types.COMPLETED, installation.id)
    addChange('outcome.reason', reasons.completed.COMPLETED, installation.id)
    addChange('outcome.isInstall', true, installation.id)

    /**
     * And if this install is part of an appointment, then update those
     * properties too.
     */
    if (installation.appointment) {
      addChange(
        'appointment.outcomeReason',
        reasons.completed.COMPLETED,
        installation.id
      )
      addChange('appointment.outcomeType', types.COMPLETED, installation.id)
      addChange('appointment.completed', true, installation.id)
    }
    /**
     * Finally, mark this as ready to submit!
     */
    addChange('submitted', true, installation.id)
  }

  /**
   * Handles the competion of the submit process, once data has been sent for
   * patching.
   */
  handleDone () {
    const { addChange, history, installation } = this.props

    const outcomes = installation.outcomes?.slice() ?? []
    outcomes.push(installation.outcome)
    addChange('outcomes', outcomes, installation.id)

    switch (installation.outcome.type) {
      case types.COMPLETED:
        if(installation.outcome.reason === reasons.completed.REJECTED){
          history.push(
            [
              '/mass',
              this.props.mass.id,
              'collection',
              this.props.collection.id
            ].join('/')
          )
          break
        }
        if(installation.outcome.reason === reasons.completed.VACANT && !installation.collection.engineering){
          history.push(
            [
              '/mass',
              this.props.mass.id,
              'collection',
              this.props.collection.id,
              'step',
              '0'
            ].join('/')
          )
          break
        }
        history.push(
          [
            '/mass',
            this.props.mass.id,
            'collection',
            this.props.collection.id,
            'device',
            installation.id
          ].join('/')
        )
        break
      default:
        history.push(
          [
            '/mass',
            this.props.mass.id,
            'collection',
            this.props.collection.id
          ].join('/')
        )
    }
  }

  /**
   * If updated account data has been found, then this will dispatch an action
   * to patch it in to the state.
   */
  handleEntityUpgrade () {
    this.props.installationUpgrade(this.state.integrityMemento)
    this.setState({ hasIntegrity: true })
  }

  render () {
    if (!this.installation) return null

    return (
      <>
        <Container sx={{ mt: 3, mb: 11 }}>
          <Box mb={3}>
            <Card>
              <AppointmentCardHeader {...this.props} />

              {this.stepper}

              <Dialog open={!this.state.hasIntegrity}>
                <Alert severity='info' variant='filled'>
                  <AlertTitle>New customer data available</AlertTitle>
                  <p>
                    Don't worry, this is just a safety check to make sure you
                    have the most up-to-date information before containing with
                    an install.
                  </p>
                  <p>
                    Before you continue, we just need to update the the customer
                    data, then continue with the installation.
                  </p>
                  <Button
                    color='inherit'
                    onClick={this.handleEntityUpgrade}
                    variant='outlined'
                  >
                    Update customer data
                  </Button>
                </Alert>
              </Dialog>

              <AccountNotesDialog
                {...this.props}
                onChange={this.handleChange}
              />
            </Card>
          </Box>
        </Container>

        <Banner color={this.state.bannerColor} label={this.state.bannerLabel} />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const mass = state.masses[ownProps.match.params.massId]
  if (!mass) return {}

  const collection = mass.collections.find(
    c => parseInt(c.id) === parseInt(ownProps.match.params.collectionId)
  )
  const installation = collection.installations.find(
    i => parseInt(i.id) === parseInt(ownProps.match.params.installationId)
  )

  return {
    collection: collection,
    installation: installation,
    mass: mass,
    offline: state.offline
  }
}

const mapDispatchToProps = dispatch => ({
  addChange: (name, value, id) => dispatch(addChange(name, value, id)),
  installationUpgrade: effect => dispatch(installationUpgrade(effect)),
  getInstallation: id => dispatch(getInstallation(id)),
  patchInstallation: effect => dispatch(patchInstallation(effect)),
  patchEnvosense: (effect, fullname, address, installationId) =>
    dispatch(patchEnvosense(effect, fullname, address, installationId))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InstallPage)
)
