export const types = {
  DEFAULT: 0,
  REARRANGED: 1,
  MISSED: 2,
  COMPLETED: 3
}

export const reasons = {
  DEFAULT: 0,
  rearranged: {
    RESIDENT_NOT_AVAILABLE: 1,
    RESIDENT_RESCHEDULED: 2,
    RESIDENT_NOT_YET_MOVED_IN: 3,
    STAFF_ASSISTANCE_REQUIRED: 4,
    AWAITING_ACTIVE_LANDLINE: 5,
    LACK_OF_CONTACT_DETAILS: 6,
    RESIDENT_DATA_INACCURATE: 7
  },
  missed: {
    LATE_RESIDENT_NO_LONGER_AVAILABLE: 8,
    STAFF_ABSENCE: 9,
    INSTALLER_ERROR: 10,
    OTHER: 11
  },
  completed: {
    COMPLETED: 12,
    REJECTED: 13,
    VACANT: 14
  }
}

export const postponedOutcomesMap = {
  'No reply at door': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident unwell': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident on holiday': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident in hospital': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident not moved in yet': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Staff assistance required': reasons.rearranged.STAFF_ASSISTANCE_REQUIRED,
  'Resident wants to rearrange': reasons.rearranged.RESIDENT_RESCHEDULED,
  'Late arrival': reasons.missed.LATE_RESIDENT_NO_LONGER_AVAILABLE,
  'No staff available': reasons.missed.STAFF_ABSENCE,
  'Engineer error': reasons.missed.INSTALLER_ERROR,
  Other: reasons.missed.OTHER
}

export const allOutcomesMap = {
  'No reply at door': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident unwell': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident on holiday': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident in hospital': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Resident not moved in yet': reasons.rearranged.RESIDENT_NOT_AVAILABLE,
  'Staff assistance required': reasons.rearranged.STAFF_ASSISTANCE_REQUIRED,
  'Resident wants to rearrange': reasons.rearranged.RESIDENT_RESCHEDULED,
  'Late arrival': reasons.missed.LATE_RESIDENT_NO_LONGER_AVAILABLE,
  'No staff available': reasons.missed.STAFF_ABSENCE,
  'Engineer error': reasons.missed.INSTALLER_ERROR,
  Other: reasons.missed.OTHER,
  Completed: reasons.completed.COMPLETED,
  Rejected: reasons.completed.REJECTED,
  Vacant: reasons.completed.VACANT
}

/**
 * Get the type of outcome, by inspecting the reason.
 */
export const getOutcomeType = outcomeReason => {
  switch (outcomeReason) {
    case reasons.completed.COMPLETED:
    case reasons.completed.REJECTED:
    case reasons.completed.VACANT:
      return types.COMPLETED
    case reasons.rearranged.AWAITING_ACTIVE_LANDLINE:
    case reasons.rearranged.LACK_OF_CONTACT_DETAILS:
    case reasons.rearranged.RESIDENT_DATA_INACCURATE:
    case reasons.rearranged.RESIDENT_NOT_AVAILABLE:
    case reasons.rearranged.RESIDENT_NOT_YET_MOVED_IN:
    case reasons.rearranged.RESIDENT_RESCHEDULED:
    case reasons.rearranged.STAFF_ASSISTANCE_REQUIRED:
      return types.REARRANGED
    case reasons.missed.INSTALLER_ERROR:
    case reasons.missed.LATE_RESIDENT_NO_LONGER_AVAILABLE:
    case reasons.missed.OTHER:
    case reasons.missed.STAFF_ABSENCE:
      return types.MISSED
    default:
      return types.DEFAULT
  }
}

/**
 * List of rescheduled outcomes where an installer may return to the property 
 * and update the outcome the same day
 */
export const sameDayReturnOutcomes = [
  'No reply at door',
  'Resident unwell',
  'Resident not moved in yet',
  'Staff assistance required',
  'Awaiting active landline',
  'Resident wants to rearrange',
  'Late arrival',
  'No staff available',
  'Engineer error'
]

/**
 * Find the human-readable string for a given outcome reason constant.
 */
export const getOutcomeReasonString = (reason = 0) => {
  const entries = Object.entries(allOutcomesMap)
  const match = entries.find(([string, key]) => key === reason)
  return match?.[0]
}

