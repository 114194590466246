import { Stepper } from '@mui/material'
import React from 'react'
import StepperActions from '../StepperActions'

/**
 * A Stepper to guide a user through the capture and verification of customer,
 * property and device information during an installation.
 *
 * A Stepper conveys progress through numbered steps. It provides a wizard-like
 * workflow.
 *
 * You should pass in an array of components, rather than a Fragment. This
 * enables BaseStepper to dynanically determine the number of steps and avoids
 * lots of unnecessary boilerplate. Ensure each component in the array has a
 * `key` attribute.
 *
 * It's important that any child components making use of the `<Step />`
 * component propagate `props` to it, e.g. `<Step {...props} />`.
 *
 * @example
 * <BaseStepper>
 *   {[<Step key={0} />, <Step key={1} />]}
 * </BaseStepper>
 */

const BaseStepper = ({
  activeStep: active = 0,
  children = [],
  onActiveStep,
  onBack,
  onFinish,
  onNext,
  onStepChange,
  title,
  valid,
  installationCompleted
}) => {
  const [activeStep, setActiveStep] = React.useState(active || 0)
  const didMount = React.useRef(false)

  React.useEffect(() => {
    didMount.current ? onStepChange?.(activeStep) : (didMount.current = true)
  }, [activeStep, onStepChange])

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    onBack?.()
  }

  const handleFinish = () => {
    onFinish?.()
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    onNext?.()
  }

  const handleActiveStep = step => {
    if (step > activeStep) return
    setActiveStep(step)
    onActiveStep?.()
  }

  /**
   * We do some iteration of the children steps here, so that a step change
   * handler can bee attached to the props. It's a little bit auto-magicky, byt
   * gets the job done for now.
   */
  return (
    <Stepper
      activeStep={activeStep}
      nonLinear
      orientation='vertical'
      sx={{ padding: 2 }}
      title={title}
    >
      {React.Children.map(children, child => {
        return React.cloneElement(child, { onActiveStep: handleActiveStep })
      })}

      <StepperActions
        activeStep={activeStep}
        onBack={handleBack}
        onFinish={handleFinish}
        onNext={handleNext}
        stepCount={children.length}
        valid={valid}
        installationCompleted={installationCompleted}
      />
    </Stepper>
  )
}

export default BaseStepper
