import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import React from 'react'

/**
 * Provides quick actions for an installation listed in a table.
 */
const CollectionTableRowAction = ({ onVacant, installationCompleted }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  /**
   * Callback to open/close menu.
   */
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Callback for when the menu is closed.
   */
  const handleClose = event => {
    setAnchorEl(null)
  }

  /**
   * This callback helps to prevent ripple animations from propagating.
   */
  const handleMouseDown = event => {
    event.stopPropagation()
  }

  /**
   * Callback for selecting to mark the property as vacant. Ensures the menu
   * is closed.
   */
  const handleVacant = event => {
    onVacant.call()
    handleClose()
  }

  if(installationCompleted){
    return null;
  }

  return (
    <>
      <IconButton onClick={handleClick} onMouseDown={handleMouseDown}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuItem onClick={handleVacant}>Property is vacant</MenuItem>
      </Menu>
    </>
  )
}

export default CollectionTableRowAction
