import {
  AssignmentInd,
  AssignmentTurnedIn,
  Block,
  Check,
  Event
} from '@mui/icons-material'
import { Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { reasons } from 'constants/outcomes'
import React from 'react'

/**
 * Would be good to remove this constant and use the sameDayReturnOutcomes constant
 * in the future
 */
const differentDayReasons = ['Resident unwell', 'Resident on holiday', 'Resident in hospital', 'Resident wants to rearrange', 'Resident not moved in yet']

/**
 * Dictates the colour used for each of the installation statuses,
 * rearranged appointments which an installer may reattempt the same day
 * are coloured differently to rearranged appointments which cannot
 *
 * @see https://mui.com/material-ui/customization/default-theme/
 */
const useStyles = makeStyles(theme => ({
  scheduled: { backgroundColor: theme.palette.info.dark },
  completed: { backgroundColor: theme.palette.success.main },
  rearranged: { backgroundColor: theme.palette.info.dark },
  anotherDayRearranged: { backgroundColor: theme.palette.warning.main },
  rejected: { backgroundColor: theme.palette.error.dark },
  vacant: { backgroundColor: theme.palette.grey[500] }
}))

/**
 * Displays an avatar which indicates the status of the installation, based on
 * the most recent outcome.
 */
const InstallationStatus = ({ installation }) => {
  const classes = useStyles()

  const outcomeStatusStyles = reason => {
    switch (reason) {
      case 1:
        if (
          (differentDayReasons.some(
            option => option === installation.appointment?.outcomeDetail
          ) &&
          (installation?.outcomes?.[installation?.outcomes?.length - 1]?.notes || "") === "") ||
          (installation?.outcomes?.[0] &&
            differentDayReasons.some(option =>
              installation?.outcomes?.[installation?.outcomes?.length - 1]?.notes?.includes(option)
            ))
        ) {
          return classes.anotherDayRearranged
        } else {
          return classes.rearranged
        }
      case 2:
        return classes.anotherDayRearranged
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return classes.rearranged
      case 9:
        return classes.rearranged
      case 10:
        return classes.rearranged
      case 11:
        return classes.rearranged
      case 12:
        return classes.completed
      case 13:
        return classes.rejected
      case reasons.completed.VACANT:
        return classes.vacant
      case 0:
      default:
        return classes.scheduled
    }
  }

  const outcomeTypeIcons = reason => {
    switch (reason) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        return <Event title='Rearranged' />
      case 12:
        return <AssignmentTurnedIn title='Completed' />
      case 13:
        return <Block title='Rejected' />
      case reasons.completed.VACANT:
        return <Check title='Vacant' />
      case 0:
      default:
        return <AssignmentInd title='Scheduled' />
    }
  }

  const outcome = installation?.outcomes?.slice().pop() || installation?.outcome

  return (
    <Avatar className={outcomeStatusStyles(outcome?.reason)}>
      {outcomeTypeIcons(outcome?.reason)}
    </Avatar>
  )
}

export default InstallationStatus
